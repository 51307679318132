import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withProps,
  withState,
  withHooks,
} from '@ngrx/signals';
import { computed, inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  Auth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  User,
} from '@angular/fire/auth';
import { PATHS } from '@lifestats/interfaces';
import { ToastStore } from './toast.store';

export interface AuthState {
  user: User | null;
  loading: boolean;
  error: string;
}

const initialState: AuthState = {
  user: null,
  loading: false,
  error: '',
};

export const AuthStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withProps(() => ({
    auth: inject(Auth),
    toastStore: inject(ToastStore),
  })),
  withComputed(({ user, error }) => {
    return {
      isAuthenticated: computed(() => !!user()),
      errorOccurred: computed(() => error() != ''),
    };
  }),
  withHooks({
    onInit({ auth, ...store }) {
      onAuthStateChanged(auth, (user) => {
        patchState(store, { user });
      });
    },
  }),
  withMethods(({ auth, toastStore, ...store }) => {
    const router = inject(Router);

    return {
      async signIn(email: string, password: string) {
        try {
          patchState(store, { loading: true, error: '' });
          await signInWithEmailAndPassword(auth, email, password);
        } catch (error: unknown) {
          patchState(store, { error: getErrorMessage(error) });
          toastStore.showError(store.error());
        } finally {
          patchState(store, { loading: false });
        }
      },

      async signUp(email: string, password: string) {
        try {
          patchState(store, { loading: true, error: '' });
          await createUserWithEmailAndPassword(auth, email, password);
          await router.navigate(['/' + PATHS.DASHBOARD]);
        } catch (error: unknown) {
          patchState(store, { error: getErrorMessage(error) });
          toastStore.showError(store.error());
        } finally {
          patchState(store, { loading: false });
        }
      },

      async signOut() {
        try {
          patchState(store, { loading: true, error: '' });
          await signOut(auth);
          await router.navigate(['/']);
        } catch (error: unknown) {
          patchState(store, { error: getErrorMessage(error) });
          toastStore.showError(store.error());
        } finally {
          patchState(store, { loading: false });
        }
      },
    };
  })
);

function getErrorMessage(error: unknown): string {
  // TODO: Localize
  return error instanceof Error ? error.message : 'Unknown Error';
}
