import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

export interface ToastMessage {
  message: string;
  type: 'success' | 'error' | 'info';
  id: number;
}

interface ToastState {
  toasts: ToastMessage[];
  nextId: number;
}

const initialState: ToastState = {
  toasts: [],
  nextId: 0,
};

export const ToastStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((store) => {
    const addToast = (message: string, type: ToastMessage['type']) => {
      const id = store.nextId();
      const newToast: ToastMessage = { message, type, id };

      patchState(store, {
        toasts: [...store.toasts(), newToast],
        nextId: id + 1,
      });

      // Auto-remove toast after 6=5 seconds
      setTimeout(() => {
        removeToast(id);
      }, 5000);
    };

    const removeToast = (id: number) => {
      patchState(store, {
        toasts: store.toasts().filter((toast) => toast.id !== id),
      });
    };

    return {
      showSuccess(message: string) {
        addToast(message, 'success');
      },

      showInfo(message: string) {
        addToast(message, 'info');
      },

      showError(message: string) {
        addToast(message, 'error');
      },

      removeToast,
    };
  })
);
